<template>
  <transition name="slide-player">
    <div
      v-if="playerActive"
      :style="{ transform: `translate(0, ${translateY}px)` }"
      class="player-wrapper"
      :class="{'transform-up': transformUp}"
    >
      <transition-group
        name="slide-player"
      >
        <div
          v-show="!playerCollapsed"
          :key="1"
          class="player-layout top-safe-space"
        >
          <!-- Content -->
          <div
            class="is-flex is-flex-direction-column ion-padding-horizontal h-100vh-minus-audio-player-and-progress"
          >
            <!-- Top bar -->
            <div
              class="ion-padding-vertical is-flex ion-align-items-center ion-justify-content-between"
              @touchstart="handleSwipeStart"
              @touchmove="handleSwiping"
              @touchend="handleSwipeOff"
            >
              <IonIcon
                class="pointer"
                :icon="chevronDown"
                size="large"
                @click="store.commit('player/setPlayerCollapse', true)"
              />

              <p class="fw-600 ion-text-center mw-60vw">{{ currentCourse.course_title }}</p>

              <IonIcon
                class="pointer"
                :icon="ellipsisHorizontalOutline"
                size="large"
                @click="presentActionSheet"
              />
            </div>

            <!-- Img & Accreditation -->
            <div
              class='is-flex is-flex-direction-column grow-1'
              style="overflow-y: auto;"
            >
              <AppImg
                :img-src="getBanner(currentEpisode)"
                img-alt="banner"
                class="img-wrap"
                border-radius="10px"
              />

              <AccreditationRequirements />
            </div>
          </div>

          <!-- Player -->
          <div class="audio-wrapper">
            <ExamProgress
              :progress-only="true"
            />

            <div class="mt-auto marquee-wrapper">
              <div
                ref="marqueeContainer"
                class="marquee-container"
              >
                <div
                  v-if="shouldAnimate"
                  class="marquee-track"
                >
                  <h1
                    ref="titleElement"
                    class="fz-18 fw-600 mt-5 mb-2 marquee-content"
                  >
                    {{ currentEpisode.title || currentEpisode.course_title }}
                  </h1>
                  <h1 class="fz-18 fw-600 mt-5 mb-2 marquee-content">
                    {{ currentEpisode.title || currentEpisode.course_title }}
                  </h1>
                </div>
                <h1
                  v-else
                  ref="titleElement"
                  class="fz-18 fw-600 mt-5 mb-2"
                >
                  {{ currentEpisode.title || currentEpisode.course_title }}
                </h1>
              </div>
              <h2 class="fz-12">{{ currentCourse.company_name }}</h2>
            </div>

            <AudioPlayer
              ref="player"
              autoplay
              :playlist="playerPlaylist"
              :playlist-start-index="playerPlaylistStartAt"
              :seek-position="currentSeekPosition"
              :course="currentCourse"
              :skip-autoplay-id="skipAutoplayId"
              :progress="progressHistory"
              :scrubbing-disabled="!!currentEpisode.disable_scrubbing"
              @seeked="initTrackingInterval"
              @track-ready="startTracking"
              @duration="streamDuration = $event"
              @autoplay-skipped="store.commit('player/setSkipAutoplayId', null)"
            />
          </div>
        </div>

        <!-- Mini Player-->
        <div
          v-show="playerCollapsed"
          :key="2"
          class="player-fixed pointer"
          :class="{ 'player-fixed-bottom': !hasPaddingBottom }"
          @click="store.commit('player/setPlayerCollapse', false)"
        >
          <MiniPlayer
            :title="currentEpisode.title"
            :subtitle="currentEpisode.description"
            :duration="trackInfo.trackDuration || currentEpisode.duration"
            :current-time="trackInfo.trackCurrentTime"
            :is-playing="trackInfo.trackPlaying"
            :podcast-media="currentEpisode.media"
            :course-media="currentCourse.media"
            :closable="isMiniplayerClosable"
            @close="store.dispatch('player/closePlayer')"
            @toggle-play.stop="player.togglePlay()"
            @pause="player.pause()"
          />
        </div>
      </transition-group>
    </div>
  </transition>
</template>

<script setup>
import {
  IonIcon,
  actionSheetController,
  modalController,
} from '@ionic/vue';
import { computed, ref, watch, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from "vuex";
import { chevronDown, ellipsisHorizontalOutline } from 'ionicons/icons';
import debounce from 'lodash/debounce';
import AccreditationRequirements from "@/components/common/AccreditationRequirements";
import AudioPlayer from "@/components/player/AudioPlayer";
import MiniPlayer from "@/components/player/MiniPlayer";
import ShareModal from '@/components/common/ShareModal';
import AppImg from '@/components/common/AppImg.vue';
import ExamProgress from '@/components/accreditation/ExamProgress';

import 'swiper/swiper.css';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n/index';

import useStreamTracking from '@/composables/player/useStreamTracking';
import usePlayerSwipe from '@/composables/player/usePlayerSwipe';
import usePlayerState from '@/composables/player/usePlayerState';
import useFavorites from '@/composables/useFavorites';

import { BackgroundMode } from '@awesome-cordova-plugins/background-mode';

const store = useStore();
const route = useRoute();
const player = ref(null);
const titleElement = ref(null);
const marqueeContainer = ref(null);
const shouldAnimate = ref(false);
const { t } = useI18n();

const {
  progressHistory,
  initTrackingInterval,
  startTracking,
  clearTrackingInterval,
  clearExpiredStorageProgress,
  currentSeekPosition,
} = useStreamTracking();

const {
  transformUp,
  translateY,
  handleSwipeStart,
  handleSwiping,
  handleSwipeOff,
} = usePlayerSwipe();

const {
  playerActive,
  playerCollapsed,
  currentEpisode,
  currentCourse,
  playerPlaylist,
  playerPlaylistStartAt,
  skipAutoplayId,
  trackInfo,
  isMiniplayerClosable,
  getBanner,
} = usePlayerState();

// Check if text overflows container
const checkTextOverflow = () => {
  if (titleElement.value && marqueeContainer.value) {
    const titleWidth = titleElement.value.scrollWidth;
    const containerWidth = marqueeContainer.value.offsetWidth;
    shouldAnimate.value = titleWidth > containerWidth;
  }
};

// Debounced version to avoid too many checks
const debouncedCheckOverflow = debounce(checkTextOverflow, 250);

onMounted(() => {
  checkTextOverflow();
  window.addEventListener('resize', debouncedCheckOverflow);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', debouncedCheckOverflow);
});

watch(() => currentEpisode.value.id, () => {
  shouldAnimate.value = false;
  setTimeout(() => {
    checkTextOverflow();
  }, 3000);
});

watch(playerActive, (newValue) => {
  if (!newValue) {
    clearTrackingInterval();
  }
});

const getPlayerPlayPauseTriggers = computed(() => store.state.player.playerPlayPauseTriggers);

watch(
  () => getPlayerPlayPauseTriggers.value.pause,
  () => {
    player.value.pause();
  },
  { deep: true },
);
watch(
  () => getPlayerPlayPauseTriggers.value.pause,
  () => {
    player.value.play();
  },
  { deep: true },
);

const handleBackgroundMode = debounce(function (isPlaying) {
  const isAndroid = store.state.app.platform === 'android';
  if (!isAndroid) {
    return;
  }
  if (isPlaying) {
    BackgroundMode.enable();
  } else {
    BackgroundMode.disable();
  }

}, 1000);

watch(
  () => trackInfo.value.trackPlaying,
  (isPlaying) => {
    handleBackgroundMode(isPlaying);
  },
);

const streamDuration = ref(null);
const hasPaddingBottom = computed(() => {
  return route.name !== 'PodcastSeries'
        && route.name !== 'Contact'
        && route.name !== 'Company'
        && route.name !== 'PublicPodcastSeries'
        && route.name !== 'AccreditationQuestions'
        && route.name !== 'ContinueWatching';
});

const { isFavorite, toggleFavourite } = useFavorites();

const presentActionSheet = async () => {
  const actionSheet = await actionSheetController.create({
    header: 'Actions',
    cssClass: 'custom-action-sheet',
    mode: 'ios',
    buttons: [
      {
        text: 'Share',
        data: {
          action: 'share',
        },
        handler: () => openShareModal(),
      },
      {
        text: isFavorite(currentEpisode.value.course_id, currentEpisode.value.id)
          ? t('savedCourses.removeFromFavourites')
          : t('savedCourses.addToFavourites'),
        role: 'selected',
        data: {
          action: 'follow',
        },
        handler: () => toggleFavourite(currentEpisode.value.course_id, currentEpisode.value.id, currentCourse.value.entity_type),
      },
      {
        text: 'Cancel',
        role: 'cancel',
        data: {
          action: 'cancel',
        },
      },
    ],
  });

  await actionSheet.present();
};

const getShareLink = () => {
  const { course_id, id } = currentEpisode.value;
  const base = `${process.env.VUE_APP_SHARE_URL}/course/${course_id}`;
  if (id) {
    return base + '?s=' + id;
  }
  return base;
};

const openShareModal = async () => {
  const { title, course_id, id } = currentEpisode.value;

  const modal = await modalController.create({
    component: ShareModal,
    componentProps: {
      shareLink: getShareLink(),
      shareText: title,
      courseId: course_id,
      streamId: id,
    },
    cssClass: 'share-modal',
  });

  await modal.present();
};

clearExpiredStorageProgress();
</script>

<style scoped lang="scss">
  .player-wrapper {
    display: block;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 6;
    pointer-events: none;
    padding: 0;
    color: white;

    * {
      pointer-events: all;
    }

    .player-layout {
      height: 100%;
      width: 100%;
      background-color: rgb(0, 0, 0);
      background-image: linear-gradient(rgba(96, 24, 24, 0.6), rgb(0, 0, 0) 85%);

      display: flex;
      flex-direction: column;

      .img-wrap {
        width: 100%;
        margin: auto;
      }

      .mw-60vw {
        max-width: 60vw;
      }

      .h-100vh-minus-audio-player-and-progress {
        height: calc(100dvh - 252px);
      }

      .audio-wrapper {
        position: fixed;
        width: 100%;
        padding: 10px;
        bottom: 0;
        left: 0;
      }
    }

    .player-fixed {
      position: fixed;
      bottom: calc(51px + var( --safe-area-inset-bottom)) !important;
      opacity: 1;
      left: 0px;
      display: block;
      width: 100%;
      padding: 0px;

      &-bottom{
        bottom: var( --safe-area-inset-bottom) !important;
      }
    }
  }

  .transform-up {
    transition: transform .3s;
  }

  // --- Marquee ---
  .marquee-wrapper {
    width: 100%;
  }

  .marquee-container {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
  }

  .marquee-track {
    display: flex;
    width: max-content;
  }

  .marquee-content {
    display: inline-block;
    white-space: nowrap;
    padding-right: 30px; /* Space between repeated texts */
    animation: marquee 20s linear infinite;
  }

  @keyframes marquee {
    0% { transform: translateX(0); }
    100% { transform: translateX(-100%); }
  }
</style>

<style>
  ion-action-sheet.custom-action-sheet {
    --button-background-selected: #434343;
  }
</style>